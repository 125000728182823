export default [
    {
        path: '',
        name: 'pageEproc',
        component: () => import('./page-eproc')
    },
    {
        path: 'baca/:kode',
        name: 'pageEprocBaca',
        component: () => import('./eproc-baca'),
        props: true
    }
]