import pageVisionMissionRouter from '../../components/page-vision-mission/index'

export default [
    {
        path: '/vision_mission',
        name: 'visionMissionRouter',
        component: () => import('../../views/vision-mission'),
        children: [
            ...pageVisionMissionRouter
        ]
    }
]