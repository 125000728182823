import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import timeago from 'vue-timeago3'
import "material-design-iconic-font/dist/css/material-design-iconic-font.css"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import api from './plugins/api'

const app = createApp(App)

// axios.defaults.baseURL = 'http://localhost:8080/'
axios.defaults.baseURL = 'https://api.plnbag.co.id/'

app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(timeago)
app.use(VueSweetalert2)
app.mount('#app')

// createApp(App)
//     .use(store)
//     .use(router)
//     .use(VueAxios, axios)
//     .mount('#app')

import "bootstrap/dist/js/bootstrap.js"