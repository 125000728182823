export default [
    {
        path: '',
        name: 'newsRouter',
        component: () => import('./page-news')
    },
    {
        path: 'read/:id',
        name: 'Read',
        component: () => import('./page-read'),
        props: true
    }
]