import Layanan from '../../components/page-layanan/index'

export default [
    {
        path: '/layanan',
        name: 'Layanan',
        component: () => import('../../views/layanan'),
        children: [
            ...Layanan
        ]
    }
]