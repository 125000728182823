import tataKelola from '../../components/page-tata-kelola/index'

export default [
    {
        path: '/tata_kelola',
        name: 'tataKelola',
        component: () => import('../../views/tata-kelola'),
        children: [
            ...tataKelola
        ]
    }
]