export default [
    {
        path: 'selengkapnya',
        name: 'pageArmadaSelengkapnya',
        component: () => import('./page-armada-selengkapnya')
    },
    {
        path: 'detail/:id',
        name: 'detailArmada',
        component: () => import('./detail-armada'),
        props: true
    }
]