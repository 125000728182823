import pageCultureRouter from '../../components/page-culture/index'

export default [
    {
        path: '/culture',
        name: 'cultureRouter',
        component: () => import('../../views/culture'),
        children: [
            ...pageCultureRouter
        ]
    }
]