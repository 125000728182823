export default [
    {
        path: '',
        name: 'pageBerita',
        component: () => import('./page-berita')
    },
    {
        path: 'baca/:id',
        name: 'Baca',
        component: () => import('./page-baca'),
        props: true
    }
]