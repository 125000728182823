import serviceRouter from '../../components/page-service/index'

export default [
    {
        path: '/service',
        name: 'service',
        component: () => import('../../views/service'),
        children: [
            ...serviceRouter
        ]
    }
]