import pageKarir from '../../components/page-karir/index'


export default [
    {
        path: '/karir',
        name: 'Karir',
        component: () => import('../../views/karir'),
        children: [
            ...pageKarir
        ]
    }
]