import pageBudayaIndex from '../../components/page-budaya/index'

export default [
    {
        path: '/budaya',
        name: 'BudayaRouter',
        component: () => import('../../views/budaya'),
        children: [
            ...pageBudayaIndex
        ]
    }
]