import pageDirectionRouter from '../../components/page-direction/index'

export default [
    {
        path: '/direction',
        name: 'directionRouter',
        component: () => import('../../views/direction'),
        children: [
            ...pageDirectionRouter
        ]
    }
]