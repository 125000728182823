import homerouter from '../../components/page-home/index'

export default [
    {
        path: '/home',
        name: 'home',
        component: () => import('../../views/home'),
        children: [
            ...homerouter
        ]
    }
]