import pageBeranda from '../../components/page-beranda/index'


export default [
    {
        path: '/',
        name: 'Beranda',
        component: () => import('../../views/beranda'),
        children: [
            ...pageBeranda
        ]
    }
]