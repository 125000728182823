import pageContactUsRouter from '../../components/page-contact-us/index'

export default [
    {
        path: '/contact_us',
        name: 'contactUs',
        component: () => import('../../views/contact_us'),
        children: [
            ...pageContactUsRouter
        ]
    }
]