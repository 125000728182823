import Selengkapnya from '../../components/page-layanan-selengkapnya'

export default [
    {
        path: '/layanan/selengkapnya/:kode',
        name: 'layananSelengkapnya',
        component: () => import('../../views/layanan_selengkapnya'),
        children: [
            ...Selengkapnya
        ],
        props: true
    }
]