import hubungiKami from '../../components/page-hubungi-kami/index'

export default [
    {
        path: '/hubungi_kami',
        name: 'hubungiKami',
        component: () => import('../../views/hubungi_kami'),
        children: [
            ...hubungiKami
        ]
    }
]