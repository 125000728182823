import { createRouter, createWebHashHistory } from 'vue-router'
import berandaRouter from './page-router/beranda-router'
import tentangKamiRouter from './page-router/tentang-kami-router'
import layananRouter from './page-router/layanan-router'
import beritaRouter from './page-router/berita-router'
import karirRouter from './page-router/karir-router'
import eprocRouter from './page-router/eproc-router'
import hubungiKamiRouter from './page-router/hubungi-kami-router'
import visiMisiRouter from './page-router/visi-misi-router'
import budayaRouter from './page-router/budaya-router'
import komisarisRouter from './page-router/komisaris-router'
import direksiRouter from './page-router/direksi-router'
import tataKelolaRouter from './page-router/tata-kelola-router'
import armadaRouter from './page-router/armada-router'
import layananLainnyaRouter from './page-router/layanan-lainnya-router'
import layananSelengkapnyaRouter from './page-router/layanan-selengkapnya-router'
import homeRouter from './page-router/home-router'
import aboutUsRouter from './page-router/about-us-router'
import serviceRouter from './page-router/service-router'
import newsRouter from './page-router/news-router'
import careerRouter from './page-router/career-router'
import eprocEmglishRouter from './page-router/eproc-emglish-router'
import contactUsRouter from './page-router/contact-us-router'
import cultureRouter from './page-router/culture-router'
import visionMissionRouter from './page-router/vision-mission-router'
import comissionerRouter from './page-router/comissioner-router'
import directionRouter from './page-router/direction-router'
import governanceRouter from './page-router/governance-router'
import otherServicesRouter from './page-router/other-services-router'
import serviceDetailRouter from './page-router/service-detail-router'

const routes = [
  ...berandaRouter,
  ...tentangKamiRouter,
  ...layananRouter,
  ...beritaRouter,
  ...karirRouter,
  ...eprocRouter,
  ...hubungiKamiRouter,
  ...visiMisiRouter,
  ...budayaRouter,
  ...komisarisRouter,
  ...direksiRouter,
  ...tataKelolaRouter,
  ...armadaRouter,
  ...layananLainnyaRouter,
  ...layananSelengkapnyaRouter,
  ...homeRouter,
  ...aboutUsRouter,
  ...serviceRouter,
  ...newsRouter,
  ...careerRouter,
  ...eprocEmglishRouter,
  ...contactUsRouter,
  ...cultureRouter,
  ...visionMissionRouter,
  ...comissionerRouter,
  ...directionRouter,
  ...governanceRouter,
  ...otherServicesRouter,
  ...serviceDetailRouter,
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
