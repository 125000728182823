import bismillahKomisaris from '../../components/page-komisaris/index'

export default [
    {
        path: '/manajemen',
        name: 'komisarisRouter',
        component: () => import('../../views/komisaris'),
        children: [
            ...bismillahKomisaris
        ]
    }
]