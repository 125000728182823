import aboutUsRouter from '../../components/page-about-us/index'

export default [
    {
        path: '/about_us',
        name: 'aboutUs',
        component: () => import('../../views/about-us'),
        children: [
            ...aboutUsRouter
        ]
    }
]