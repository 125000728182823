import eprocurementRouter from '../../components/page-eprocurement/index'

export default [
    {
        path: '/eprocurement',
        name: 'eprocEn',
        component: () => import('../../views/eproc_en'),
        children: [
            ...eprocurementRouter
        ]
    }
]