export default [
    {
        path: '',
        name: 'eprocurementRouter',
        component: () => import('./page-eprocurement')
    },
    {
        path: 'read/:kode',
        name: 'readEprocurement',
        component: () => import('./read-eprocurement'),
        props: true
    }
]