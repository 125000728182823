import Direksi from '../../components/page-direksi/index'

export default [
    {
        path: '/direksi',
        name: 'Direksi',
        component: () => import('../../views/direksi'),
        children: [
            ...Direksi
        ]
    }
]