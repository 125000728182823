import pageGovernanceRouter from '../../components/page-governance/index'

export default [
    {
        path: '/governance',
        name: 'governanceRouter',
        component: () => import('../../views/governance'),
        children: [
            ...pageGovernanceRouter
        ]
    }
]