import LayananLainnya from '../../components/page-layanan-lainnya/index'

export default [
    {
        path: '/layanan_lainnya',
        name: 'layananLainnya',
        component: () => import('../../views/layanan_lainnya'),
        children: [
            ...LayananLainnya
        ]
    }
]