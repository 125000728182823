import detailService from '../../components/page-service-detail/index'

export default [
    {
        path: '/service/other/:kode',
        name: 'serviceDetail',
        component: () => import('../../views/service_detail'),
        children: [
            ...detailService
        ],
        props: true
    }
]