import TentangKami from '../../components/page-tentang-kami/index'

export default [
    {
        path: '/tentang_kami',
        name: 'tentangKami',
        component: () => import('../../views/tentang-kami'),
        children: [
            ...TentangKami
        ]
    }
]