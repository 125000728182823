export default [
    {
        path: '',
        name: 'pageKarir',
        component: () => import('./page-karir')
    },
    {
        path: 'baca/:kode',
        name: 'pageKarirBaca',
        component: () => import('./karir-baca'),
        props: true
    }
]