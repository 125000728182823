import pageVisiMisi from '../../components/page-visi-misi/index'

export default [
    {
        path: '/visi_misi',
        name: 'VisiMisi',
        component: () => import('../../views/visi_misi'),
        children: [
            ...pageVisiMisi
        ]
    }
]