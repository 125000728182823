import Berita from '../../components/page-berita/index'

export default [
    {
        path: '/berita',
        name: 'Berita',
        component: () => import('../../views/berita'),
        children: [
            ...Berita
        ]
    }
]