export default [
    {
        path: '',
        name: 'career',
        component: () => import('./page-career')
    },
    {
        path: 'read/:kode',
        name: 'readCareer',
        component: () => import('./read-career'),
        props: true
    }
]