import Eproc from '../../components/page-eproc/index'

export default [
    {
        path: '/eproc',
        name: 'Eproc',
        component: () => import('../../views/eproc'),
        children: [
            ...Eproc
        ]
    }
]