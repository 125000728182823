import newsRouter from '../../components/page-news/index'

export default [
    {
        path: '/news',
        name: 'news',
        component: () => import('../../views/news'),
        children: [
            ...newsRouter
        ]
    }
]