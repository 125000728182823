import pageSelengkapnya from '../../components/page-armada-selengkapnya/index'

export default [
    {
        path: '/armada/',
        name: 'armadaRouter',
        component: () => import('../../views/armada'),
        children: [
            ...pageSelengkapnya
        ]
    }
]